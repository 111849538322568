import React, { Component } from "react";
// import PrivateRoute from "../components/PrivateRoute";
//import User from "../components/User";
import Videos from "../components/Videos";

export class Home extends Component {
  render() {
    return (
      <>
        <Videos />
        {/* <User /> */}
      </>
    );
  }
}

export default Home;
