import React from "react";
import LoginForm from "../components/LoginForm";
import LoginIllustration from "../components/LoginIllustration";
export default function Login() {
  return (
    <>
      <h1>Login to your account</h1>
      <div className="column">
        <LoginIllustration />
        <LoginForm />
      </div>
    </>
  );
}
