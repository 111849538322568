import React from "react";
import success from "../assets/images/success.png";
import useFetch from "../hooks/useFetch";
import classes from "../styles/Summary.module.css";

export default function Summary({ score, noq }) {
  const getKeyword = () => {
    if ((score / (noq * 5)) * 100 < 50) {
      return "nature";
    } else if ((score / (noq * 5)) * 100 < 75) {
      return "tigers";
    } else if ((score / (noq * 5)) * 100 < 100) {
      return "people";
    } else {
      return "excellent";
    }
  };
  const { loading, error, result } = useFetch(
    `https://api.pexels.com/v1/search?query=${getKeyword}&per_page=1`,
    "GET",
    {
      Authorization: process.env.REACT_APP_PEXELS_KEY,
    }
  );

  const image = result ? result?.photos[0].src.medium : success;

  return (
    <>
      <div className={classes.summary}>
        <div className={classes.point}>
          <p className={classes.score}>
            Your score is <br />
            {score} out of {noq * 5}
          </p>
        </div>
        {loading && <div className="classes.badge">Loading your badge..</div>}
        {error && <div className="classes.badge">An error occured</div>}
        {!loading && !error && (
          <div className={classes.badge}>
            <img src={image} alt="Success" />
          </div>
        )}
      </div>
    </>
  );
}
